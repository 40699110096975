import React from 'react';
import { Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import Link from "gatsby-link";
import HtmlParser from "react-html-parser";

function PodcastSection({data}) {
    return (
        <div className="podcast_section p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.podcast_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'podcast_title'))}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(checkData(data, 'podcast_txt'))}</div>
                    </Col>
                </Row>
                <Row className="pt-100">
                    {data?.podcast_img?.map((value, i) => (
                        <Col xs={12} md={6} lg={4} xl={4} xxl={4} className="mb-4 text-center">
                            <Link to={value.link} target="_blank">
                                <img
                                    src={`${mediaBaseURL}${checkData(value, 'image')}`}
                                    className="img-fluid"
                                    alt="image_podcast"/>
                            </Link>
                        </Col>
                    ))}
                </Row>

            </Container>
        </div>
    );
}

export default PodcastSection;
import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";

function Podcastbg({data}) {
    return (
        <div className="banner_bg p-100"
             style={{backgroundImage: `url(${mediaBaseURL}${data?.story_image})`}}>
            <Container className="h-100">
                <Row className="h-100">
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Podcastbg;
import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Banner({data}) {
    return (
        <div className="services_banner ">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div className="example" data-text={data?.banner_body_txt}>
                            <h1>{HtmlParser(checkData(data, 'banner_title'))}</h1>
                        </div>
                        <div
                            className="banner_content_txt listing_flex">{HtmlParser(checkData(data, 'banner_txt'))}</div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'banner_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'banner_title')}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Banner;
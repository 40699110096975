import React, {useEffect, useState} from 'react';
import Layout from "../layout/layout";
import Banner from "./banner";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Breadcrumbs from "../components/breadcrumbs";
import Seo from "../layout/seo";
import {checkData} from "../../helper";
import PodcastStory from "./podcast_story";
import PodcastSection from "./podcast_section";
import Podcastlisting from "./podcastlisting";
import Loader from "../components/Loader";
import Podcastbg from "./podcastbg";
import Featured from "../home/featured";
import Partner from "../home/partner";

function Index(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);


    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/podcast-page`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);

    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>

                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Podcast"/>
                    <Banner data={userData}/>
                    <PodcastStory data={userData}/>
                    <Podcastbg data={userData}/>
                    <PodcastSection data={userData}/>
                    <Podcastlisting data={userData}/>
                    <Featured/>
                    <Partner/>
                </>
            }
        </Layout>
    );
}

export default Index;
